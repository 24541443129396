<template>
    <div>
        <!-- Titlebar -->
        <div class="parallax-container">
            <div class="parallax titlebar">
                <div id="titlebar">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <h2>الأسئلة المتكررة</h2>
                                <span>قد تساعدك هذه الأسئلة</span>
                                <!-- Breadcrumbs -->
                                <nav id="breadcrumbs">
                                    <ul>
                                        <li><router-link to="/">الرئيسية</router-link></li>
                                        <li>الأسئلة المتكررة</li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <parallax :speed-factor="0.3" breakpoint="(min-width: 80px)">
                <img :src="titleBg" alt="Parallax Image">
            </parallax>
        </div>

        <!-- Container -->
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-sm-12 col-md-offset-2 col-sm-offset-0">
                    <!-- Headline -->
                    <!-- <div class="property-description margin-bottom-20">
                        <h3 class="desc-headline mt-0">اغلب الاسئلة الشائعة</h3>
                        <div>
                            <p>
                                لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور
                                أنكايديديونتيوت لابوري ات دولار ماجنا أليكيوا
                                لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور
                            </p>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 col-sm-12 col-md-offset-2 col-sm-offset-0 faqs-collapse">
                    <b-card no-body class="mb-2" v-for="faq in faqs" :key="faq._id">
                        <b-card-header header-tag="header" role="tab" v-b-toggle="`accordion-${faq._id}`">
                            <div>
                                <span>{{faq.question}}</span>
                                <i class="hi-angle-down icon"></i>
                            </div>
                        </b-card-header>
                        <b-collapse :id="`accordion-${faq._id}`" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                            <b-card-text>{{ faq.answer }}</b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </div>
        </div>
        <!-- Container / End -->

        <div class="margin-top-55"></div>
    </div>
</template>

<script>
import Parallax from 'vue-parallaxy';
import { realEstateService } from '../../services';

import '@/styles/pages/faq.scss';

export default {
    layout: 'main',
    components: {
        Parallax,
    },

    data() {
        return {
            titleBg: require('../../assets/images/home-parallax-2.jpg'),
            faqs: [],
        };
    },
    mounted(){
        realEstateService.getFAQs().then( res =>{
            this.faqs = res.data?.faqs;
        }, error => {
            console.log(error);
        });
    }
};
</script>


<style>
div.Masthead:after {
    background-color: rgb(61, 186, 126);
    opacity: 0.9;
}
</style>
